import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer, YAxis } from 'recharts';

const TokenDetail = () => {
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);
  const [tokens, setTokens] = useState();
  const [chartData, setChartData] = useState();
  const [loading, setLoading] = useState();
  const localPinID = window.location.pathname.split("/")[2];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Claimed : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };


  useEffect(() => {

    const getTokenChartData = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/tokens/${localPinID}/chartdata`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data = await res.json();
        console.log(data);
        setChartData(data);
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.show = true;
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          navigate("/");
        } else {
          alertContext.msg = "Error getting tokens";
        }
      }
    }

    const getTokens = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/tokens/${localPinID}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data = await res.json();
        setTokens(data);
        setLoading(false);
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.show = true;
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          navigate("/");
        } else {
          alertContext.msg = "Error getting tokens";
        }
      }
    };

    setLoading(true);
    getTokens();
    getTokenChartData();
  }, [alertContext, localPinID, navigate]);

  return loading ? (
    <Loader />
  ) : tokens?.length === 0 ? (
    <h1>No Tokens Found</h1>
  ) : (
    <div style={{ width: '90%', margin: '0 auto' }}>
      <Link onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
        &larr; Back
      </Link>
      <h1>Claimed PiNs ({tokens?.length})</h1>

      <hr />
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={chartData}>
          <XAxis dataKey="update_date" />
          <YAxis />
          <Bar dataKey="token_count" fill="#8884d8" />
          <Tooltip content={<CustomTooltip />} />
        </BarChart>
      </ResponsiveContainer>
      <hr />

      <div className="row">
        <div className="col-2">
          <b>Token Number</b>
        </div>
        <div className="col-4">
          <b>Attendee Email</b>
        </div>
        <div className="col-4">
          <b>Received On</b>
        </div>
      </div>
      {tokens?.map((token) => {
        return (
          <div className="row" key={token?.tokennumber}>
            <div className="col-2">
              #{token?.tokennumber}
            </div>
            <div className="col-4">
              {token.email}
            </div>
            <div className="col-4">
              {new Date(token.update_at).toLocaleString()}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TokenDetail;
