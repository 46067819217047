import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import Loader from "../Loader";
import { Link } from "react-router-dom";

const PresentationsList = () => {
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);
  const [presentationItems, setPresentationItems] = useState();
  const [loading, setLoading] = useState();
  const localPinID = window.location.pathname.split("/")[2];

  function sendReminder(urlpath) {
    // TODO: Implement this function
  }
  useEffect(() => {
    const getAllPresentations = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/events/${localPinID}/presentations`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data = await res.json();
        setPresentationItems(data);
        setLoading(false);
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.show = true;
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          navigate("/");
        } else {
          alertContext.msg = "Error getting presentations!";
        }
      }
    };

    setLoading(true);
    getAllPresentations();
  }, [alertContext, localPinID, navigate]);

  return loading ? (
    <Loader />
  ) : presentationItems?.length === 0 ? (
    <>
      <Link to="/events" style={{ textDecoration: "none" }}>
        &larr; Back
      </Link>
      <h1>No Presentations found!  </h1> 
      <h3> Start with defining an agenda topic and invite Presenter to upload presentation.</h3>
      <div style={{ textAlign: "right" }}>
        <button
          className="btn btn-primary"
          onClick={() => navigate(`/newpresentation/${localPinID}`)}
        >
          New Topic
        </button>
      </div>
    </>
  ) : (
    <div>
      <h1>Presentations ({presentationItems?.length})</h1>
      <Link to="/events" style={{ textDecoration: "none" }}>
        &larr; Back
      </Link>

      <div style={{ textAlign: "right" }}>
        <button
          className="btn btn-primary"
          onClick={() => navigate(`/newpresentation/${localPinID}`)}
        >
          New Topic
        </button>
      </div>
      <div className="row" key="xyz">
        <div className="col-2">
          <b>Topic</b>
        </div>
        <div className="col">
          <b>Presenter</b>
        </div>
        <div className="col">
          <b>Presenter Email</b>
        </div>
        <div className="col">
          <b>Presentation</b>
        </div>
      </div>
      {presentationItems?.map((presentationItem) => {
        return (
          <div className="row" key={presentationItem.id}>
            <div className="col-2" key={"col" + presentationItem.id}>
              {presentationItem.name}
            </div>
            <div className="col" key={"col" + presentationItem.id}>
              {presentationItem.authorname}
            </div>
            <div className="col" key={"col" + presentationItem.id}>
              {presentationItem.authoremail}
            </div>
            <div className="col" key={"col" + presentationItem.presentation}>
              {presentationItem.presentation ? (
                <a href={presentationItem.url} target="_blank" rel="noopener noreferrer">
                  {presentationItem.filename}
                </a>
              ) : (
                <button className="btn btn-warning" onClick={() => sendReminder(presentationItem.id)}>
                  Send Reminder
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PresentationsList;
