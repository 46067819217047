import { useContext, useState, useEffect } from "react";
import AlertPopup from "../Alert/alert";
import { useNavigate } from "react-router";
import axios from "axios";
import { AlertContext } from "../../App";
import "./index.css";

const UploadPresentation = () => {
  const [eventName, setEventName] = useState();
  const [presenterName, setPresenterName] = useState();
  const [presenterEmail, setPresenterEmail] = useState();
  const [orgName, setOrgName] = useState();
  const [timestampFrom, setTimestampFrom] = useState();
  const [timestampTo, setTimestampTo] = useState();

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('id');

  useEffect(() => {
    function getEventInfoByCode() {
      fetch(process.env.REACT_APP_BASEURL + `/presenters/eventinfo?code=${code}`)
        .then((res) => res.json())
        .then((data) => {
          if (data["error"]) {
            setError(data["error"]);
          } else {
            setEventName(data["name"]);
            setPresenterName(data["presentation"].authorname);
            setPresenterEmail(data["presentation"].authoremail);
            setTimestampFrom(data["timestampfrom"]);
            setTimestampTo(data["timestampto"]);
            setOrgName(data["orgname"]);
            setName(data["presentation"].name);
          }
        });
    }

    getEventInfoByCode();
  }, [code]);

  const handlePresentationUpload = async (e) => {
    e.preventDefault();
    const file = document.querySelector("input[type=file]").files[0];
    setError(null);

    const errors = [];

    if (!description || description?.length === 0) {
      errors.push("Description is required.");
    }

    if (!file || file?.length === 0) {
      errors.push("File is required");
      setError(errors);
      return;

    }

    // File size should be less than 10MB
    if (file?.size > 10000000) {
      errors.push("File size should be less than 10MB");
    }

    if (!file.name.toLowerCase().includes("pdf")) {
      errors.push("File must be pdf!");
    }

    console.log("errors", errors);
    if (errors.length > 0) {
      setError(errors);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    let ipfsHash;

    try {
      const ipfs = await axios.post(
        process.env.REACT_APP_BASEURL + "/nftarticle",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      ipfsHash = `ipfs://${ipfs?.data?.IpfsHash}`;
    } catch (err) {
      console.log("err", err);
    }

    // Encode the description for safe storage
    const encodeDescription = encodeURIComponent(description);
    console.log(encodeDescription);

    const res = await fetch(
      process.env.REACT_APP_BASEURL + "/presenters/presentation/" + code,
      {
        method: "PUT",
        body: JSON.stringify({
          description,
          presentation: ipfsHash,
          filename: file.name,
        }),
        headers: {
          "content-type": "application/json",
        },
      }
    );

    const data = await res.json();
    console.log(data);

    // Decode the stored description
    if (data["error"]) {
      setError(data["error"]);
      return;
    } else {
      alertContext.show = true;
      alertContext.variant = "primary";
      alertContext.msg = "Thank you for uploading the presentation!";
      navigate("/");
    }
  };

  const handleErrorpopup = () => {
    setError(null);
  };


  return (
    <div className="container">
      {error && (
        <AlertPopup
          message={error}
          variant="danger"
          setShow={handleErrorpopup}
        />
      )}
      <div className="col-md-2 col-sm-4 col-lg-10 mx-auto">
        <h1>Welcome {presenterName} &lt;{presenterEmail}&gt; </h1>
        <h3>Event Info</h3>
        <p>
          <b>Event Name:</b> {eventName} <br />
          <b>Organized by:</b> {orgName} <br />
          <b>Date/time: </b>
          {new Date(timestampFrom).toLocaleString()} to {new Date(timestampTo).toLocaleString()}
        </p>
        <hr />
        <h3 className="login-heading mb-4">Upload Presentation</h3>
        <p>
          <b>Name: </b> {name} <br />
        </p>
        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handlePresentationUpload}
        >
          <div className="form-group mb-4" style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "50%", paddingRight: "10px" }}>
              <label htmlFor="description">Description *</label>
              <textarea
                rows={8}
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-group mb-4">
            <label htmlFor="artifact">Presentation (PDF only) *</label>

            <input
              type="file"
              className="form-control"
              required
              name="artifact"
              accept="application/pdf"
              placeholder="pdf files only"
            />
          </div>

          <div className="d-grid">
            <button
              type="button"
              className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
              onClick={handlePresentationUpload}>
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadPresentation;
