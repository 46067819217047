import { useEffect, useState } from "react";
import "./dashboard.css";
import { Outlet, useNavigate } from "react-router";

function Dashboard({ login }) {
  const [eventData, setEventData] = useState({
    events: {
      total: 0,
      active: 0
    },
    souvenirs: {
      total: 0
    },
    presentations: {
      total: 0
    },
    users: {
      total: 0
    }
  });
  const [Accinfo, setAccInfo] = useState({
    souvenir: {
      issued: 0,
      waitlist: 0,
      waitlistaccepted: 0,
    },
    purchased: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/");
    }

    const getData = async () => {
      const res = await fetch(
        process.env.REACT_APP_BASEURL + "/members/dashboard",
        {
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );
      const data = await res.json();
      console.log(data);

      setEventData(data);
    };

    getData();
  }, [navigate]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    if (!user) {
      return navigate("/");
    }
    const getBalance = async () => {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + "/members/profile/balance",
        {
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );
      const data1 = await response.json();
      // console.log(data1);
      setAccInfo(data1);
    };

    getBalance();
  }, [navigate]);

  const handleNavigation = (page) => navigate(`${page}`);
  return (
    <>
      <div>
        <h1>Dashboard</h1>
        {eventData.events.total === 0 ? (
          <div style={{ textAlign: "right", width: "100%" }}>
            <a className="btn btn-primary" href="/newevent">
              Create your first event
            </a>{" "}
            <br />
            <br />
          </div>
        ) : (
          <div></div>
        )}
        <div class="row">
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              onClick={() => handleNavigation("/events")}
              style={{ width: "18rem", marginBottom: "1.5rem" }}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Total Events
                </h5>
                <p
                  className="card-text"
                  title="Total number of events"
                  data-toggle="tooltip"
                >
                  {eventData.events.total}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              style={{ width: "18rem", marginBottom: "1.5rem" }}
              onClick={() => handleNavigation("/events/active")}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Active Events
                </h5>
                <p
                  className="card-text"
                  title="Number of active events"
                  data-toggle="tooltip"
                >
                  {eventData.events.active}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              style={{ width: "18rem", marginBottom: "1.5rem" }}
              onClick={() => handleNavigation("/billing")}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Account Balance
                </h5>
                <p
                  className="card-text"
                  title="PiNs available to issue"
                  data-toggle="tooltip"
                >
                  {Accinfo.purchased - (Accinfo.souvenir.issued + Accinfo.souvenir.waitlist)} <span style={{ fontSize: "10px" }}>PiNs</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              style={{ width: "18rem", marginBottom: "1.5rem" }}
              onClick={() => handleNavigation("/events")}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Souvenirs
                </h5>
                <p
                  className="card-text"
                  title="Souvenirs"
                  data-toggle="tooltip"
                >
                  {eventData.souvenirs.total}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              style={{ width: "18rem", marginBottom: "1.5rem" }}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Claimed Souvenir PiNs
                </h5>
                <p
                  className="card-text"
                  title="Number of PiNs claimed"
                  data-toggle="tooltip"
                >
                  {Accinfo.souvenir.issued} <span style={{ fontSize: "10px" }}>Souvenirs</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              style={{ width: "18rem", marginBottom: "1.5rem" }}
              onClick={() => handleNavigation("/waitlist")}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Unclaimed Souvenir PiNs
                </h5>
                <p
                  className="card-text"
                  title="Unclaimed PiNs emailed to attendees"
                  data-toggle="tooltip"
                >
                  {Accinfo.souvenir.waitlist} <span style={{ fontSize: "10px" }}>Souvenirs</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              style={{ width: "18rem", marginBottom: "1.5rem" }}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Presentations
                </h5>
                <p
                  className="card-text"
                  title="All Presentations"
                  data-toggle="tooltip"
                >
                  {eventData.presentations.total}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Dashboard;
