import { Outlet, useLocation, useNavigate } from "react-router";
import "./events.css";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AllPins = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [filter, setfilter] = useState("");
  const handlefilter = ({ currentTarget: input }) => {
    const { value } = input;
    navigate(value, { relative: "path" });
    setfilter(value);
  };

  useEffect(() => {
    const patharray = location.pathname.split("/");
    if (patharray.length > 2) {
      setfilter(patharray[2]);
    }
  }, [location]);

  return (
    <div className="container">
      <br />

      <div>
        <h2>All Events</h2>
        <div style={{ textAlign: "right", width: "100%" }}>
          <a className="btn btn-primary" href="/newevent">
            Create an event
          </a>
          <br />
          <br />
        </div>
      </div>

      <div className="pins-header">
        <Form.Select
          className="select-filter"
          aria-label="Default select example"
          onChange={handlefilter}
          value={filter}
        >
          <option value="">All</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </Form.Select>
      </div>
      <br />
      <br />

      <Outlet />
    </div>
  );
};

export default AllPins;
