import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import Loader from "../Loader";
import { Link } from "react-router-dom";

const AwardsList = () => {
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);
  const [allAwards, setAllAwards] = useState();
  const [loading, setLoading] = useState();
  const localPinID = window.location.pathname.split("/")[2];

  useEffect(() => {
    const getAllAwards = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/events/${localPinID}/awards`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data = await res.json();
        setAllAwards(data.awards);
        setLoading(false);
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.show = true;
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          navigate("/");
        } else {
          alertContext.msg = "Error getting awards!";
        }
      }
    };

    setLoading(true);
    getAllAwards();
  }, [alertContext, localPinID, navigate]);

  return loading ? (
    <Loader />
  ) : allAwards?.length === 0 ? (
    <>
      <Link to="/events" style={{ textDecoration: "none" }}>
        &larr; Back
      </Link>
      <h1>No Awards found!</h1>
      <div style={{ textAlign: "right" }}>
        <button
          className="btn btn-primary"
          onClick={() => navigate(`/newaward/${localPinID}`)}
        >
          New Award
        </button>
      </div>
    </>
  ) : (
    <div>
      <h1>Awards ({allAwards?.length})</h1>
      <Link to="/events" style={{ textDecoration: "none" }}>
        &larr; Back
      </Link>

      <div style={{ textAlign: "right" }}>
        <button
          className="btn btn-primary"
          onClick={() => navigate(`/newaward/${localPinID}`)}
        >
          New Award
        </button>
      </div>
      <div className="row" key="xyz">
        <div className="col-2">
          <b>Award Title</b>
        </div>
        <div className="col">
          <b>Recipient</b>
        </div>
        <div className="col">
          <b>Recipient Email</b>
        </div>
        <div className="col">
          <b>Actions</b>
        </div>
      </div>
      {allAwards?.map((anAward) => {
        return (
          <div className="row" key={anAward.id}>
            <div className="col-2" key={"col1-" + anAward.id}>
              {anAward.name}
            </div>
            <div className="col" key={"col2-" + anAward.id}>
              {anAward.recipientname}
            </div>
            <div className="col" key={"col3-" + anAward.id}>
              {anAward.recipientemail}
            </div>
            <div className="col" key={"col5-" + anAward.presentation}>
              {!anAward.recipientemail && (
                <button
                  className="btn btn-secondary"
                  onClick={() => navigate(`/events/${localPinID}/awards/${anAward.id}/assign`)}
                >
                  Assign Award
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AwardsList;
