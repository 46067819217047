import { Alert } from "react-bootstrap";

function AlertPopup({ message, variant, setShow }) {
  return (
    <Alert
      variant={variant}
      onClose={() => setShow()}
      dismissible
      style={{ zIndex: 99 }}
    >
      <div style={{ display: "flex" }}>
        {message instanceof Array ? (
          message.map((msg, index) => (
            <p className="mx-2 large-font" key={index}>
              {" "}
              {msg}{" "}
            </p>
          ))
        ) : (
          <p>{message}</p>
        )}
      </div>
    </Alert>
  );
}
export default AlertPopup;
