import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import AlertPopup from "../Alert/alert";
import { Link } from "react-router-dom";

const NewPresentation = () => {
  const [prename, setPreName] = useState();
  const [author, setAuthor] = useState();
  const [email, setEmail] = useState();
  const [error, setError] = useState(null);

  const localEventId = window.location.pathname.split("/")[2];

  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);

  const handleNewPresentation = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    setError(null);

    if (!user) {
      return navigate("/");
    }
    const errors = [];

    if (!prename || prename.length === 0) {
      errors.push("Presentation name is required");
    }
    if (!author || author.length === 0) {
      errors.push("Author is required");
    }
    if (!email || email.length === 0) {
      errors.push("Email is required");
    }

    const res = await fetch(
      process.env.REACT_APP_BASEURL +
      `/members/events/${localEventId}/presentations`,
      {
        method: "post",
        body: JSON.stringify({
          presentation: prename,
          authorname: author,
          authoremail: email,
        }),
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );

    const data1 = await res.json();
    console.log(data1);

    if (data1["error"]) {
      setError(data1["error"]);
      return;
    } else {
      alertContext.show = true;
      alertContext.variant = "primary";
      alertContext.msg = "A new presentation has been created!";
      navigate("/presentations/" + localEventId);
    }
  };

  const handleErrorpopup = () => {
    setError(null);
  };
  return (
    <div className="container">
      {error && (
        <AlertPopup
          message={error}
          variant="danger"
          setShow={handleErrorpopup}
        />
      )}
      <div className="col-md-2 col-sm-4 col-lg-10 mx-auto">
        <Link to={`/presentations/${localEventId}`} style={{ textDecoration: "none" }}>
          ← Back
        </Link>
        <h3 className="login-heading mb-4">New Topic</h3>
        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handleNewPresentation}
        >
          <div className="form-group mb-4">
            <label htmlFor="name"> Name *</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={prename}
              onChange={(e) => setPreName(e.target.value)}
              maxLength={500}
              required
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="author"> Presenter Name *</label>
            <input
              type="text"
              name="author"
              className="form-control"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              maxLength={500}
              required
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="email">Presenter Email *</label>
            <input
              type="text"
              name="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={500}
              required
            />
          </div>
          <div className="d-grid">
            <button
              type="submit"
              className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPresentation;
