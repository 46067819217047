import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AlertPopup from "../Alert/alert";
import { AlertContext } from "../../App";
import mainPicture from "../../images/signup.jpg";
import ValidPassword from "../Password/ValidPassword";

const Signup = () => {
  const [companyName, setCompanyName] = useState();
  const [address, setAddress] = useState();
  const [phone, setPhone] = useState();
  const [customer_email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [showValidation, setShowValidation] = useState(false);
  // const [validations, setValidations] = useState({
  //   hasLowercase: false,
  //   hasUppercase: false,
  //   hasNumber: false,
  //   hasSpecialchar: false,
  //   hasMinLength: false,
  // });
  // const [passwordMatches, setPasswordMatches] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);

  const handleSignUp = (e) => {
    const errors = [];

    if (!companyName || companyName.length === 0) {
      errors.push("Company name is required");
    }

    if (!customer_email || customer_email?.length === 0) {
      errors.push("Email is required");
    }

    if (errors.length > 0) {
      setError(errors);
      return;
    }

    fetch(process.env.REACT_APP_BASEURL + "/members/signup", {
      method: "post",
      body: JSON.stringify({
        name: companyName,
        customer_email,
        password,
        address,
        phone,
      }),
      headers: { "content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data["error"]) {
          setError(data["error"]);
        } else {
          alertContext.show = true;
          alertContext.msg =
            "Congratulations! Your signup was successful. Please check your email for verification.";
          navigate("/signin");
        }
      })
      .catch((err) => {
        setError(
          "Error occurred during signup. Contact contact@clifty.io for support."
        );
      });

    e.preventDefault();
  };

  // // update the validation status as the password change
  // const handleConfirmpasswordChange = (e) => {
  //   const inputConfirmPassword = e.target.value;
  //   setConfirmPassword(inputConfirmPassword);

  //   // check if confirm password matches the original password
  //   setPasswordMatches(password === inputConfirmPassword);
  // };
  // // Update the validation statuses as the password changes
  // const handlePasswordChange = (e) => {
  //   const inputPassword = e.target.value;
  //   setValidations({
  //     hasLowercase: /[a-z]/.test(inputPassword),
  //     hasUppercase: /[A-Z]/.test(inputPassword),
  //     hasNumber: /\d/.test(inputPassword),
  //     hasSpecialchar: /[!@#$%^&*(),.?":{}|<>]/.test(inputPassword),
  //     hasMinLength: inputPassword.length >= 8,
  //   });
  //   // Check if confirm password doesn't matches the updated password
  //   setPasswordMatches(inputPassword === confirmPassword);

  //   setPassword(inputPassword);
  // };

  const handleErrorpopup = () => {
    setError(null);
  };

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      handleSignUp();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    companyName,
    address,
    phone,
    customer_email,
    password,
    confirmPassword,
    keyDownHandler,
  ]);

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="demo-content">
          <img src={mainPicture} alt="" style={{ width: "100%" }} />
        </div>
        <p style={{ fontSize: "1.5rem" }}>
          Sign Up for a FREE account and distribute digital souvenirs to your
          event attendees. You get 100 free Souvenir PiNs to start with. No
          credit card required.
        </p>
      </div>
      <div className="col-md-6">
        <div className="demo-content bg-alt"></div>
        <div style={{ maxWidth: "600px", padding: "0 2rem" }}>
          <h2>Clifty for Members</h2>
          <hr />
          <br />
          {error && (
            <AlertPopup
              message={error}
              variant="danger"
              setShow={handleErrorpopup}
            />
          )}
          <div
            className="mx-auto"
            style={{ maxWidth: "600px", padding: "0 2rem" }}
          >
            <h3 className="login-heading mb-4">Member Sign up</h3>
            <form>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="company"
                  onChange={(e) => setCompanyName(e.target.value)}
                  autoComplete="off"
                  max={100}
                  maxLength={100}
                />
                <label htmlFor="company">Company Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  onChange={(e) => setAddress(e.target.value)}
                  autoComplete="off"
                />
                <label htmlFor="address">Address</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                  autoComplete="off"
                />
                <label htmlFor="phone">Phone</label>
              </div>
              <div className="form-floating mb-3">
                <hr />
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={customer_email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                  required
                  max={200}
                  maxLength={200}
                />
                <label htmlFor="email">Email address</label>
              </div>
              {/* <div className="form-floating mb-3"> */}
              {/* <input
                  type="password"
                  className="form-control"
                  name="password"
                  onChange={handlePasswordChange}
                  onFocus={() => setShowValidation(true)}
                  onBlur={() => setShowValidation(false)}
                /> */}
              {/* <br /> */}

              {/* {showValidation && (
                  <ul>
                    <li
                      style={{
                        color: validations.hasLowercase ? "green" : "red",
                      }}
                    >
                      {validations.hasLowercase ? "✔️" : "❌"} At least one
                      lowercase letter
                    </li>
                    <li
                      style={{
                        color: validations.hasUppercase ? "green" : "red",
                      }}
                    >
                      {validations.hasUppercase ? "✔️" : "❌"} At least one
                      uppercase letter
                    </li>
                    <li
                      style={{ color: validations.hasNumber ? "green" : "red" }}
                    >
                      {validations.hasNumber ? "✔️" : "❌"} At least one number
                    </li>
                    <li
                      style={{
                        color: validations.hasSpecialchar ? "green" : "red",
                      }}
                    >
                      {validations.hasSpecialchar ? "✔️" : "❌"} At least one
                      special character (!@#$%^&*)
                    </li>
                    <li
                      style={{
                        color: validations.hasMinLength ? "green" : "red",
                      }}
                    >
                      {validations.hasMinLength ? "✔️" : "❌"} Minimum 8
                      characters
                    </li>
                  </ul>
                )} */}

              <ValidPassword
                label="Password"
                name="Password"
                id="key"
                datatestid="key1"
                value={password}
                setvalue={setPassword}
                validcheck={{
                  hasUppercase: {
                    valid: false,
                    message: "At least one Upper Case",
                  },
                  hasLowercase: {
                    valid: false,
                    message: "At least one Lower Case",
                  },

                  hasNumber: { valid: false, message: "At least one number" },
                  hasMinlength: {
                    valid: false,
                    message: "Minimum 8 characters",
                  },
                  hasSpecialChar: {
                    valid: false,
                    message: "At least one special character",
                  },
                }}
              />

              {/* <label htmlFor="password">Password</label> */}
              {/* </div> */}
              {/* <div className="form-floating mb-3"> */}
              {/* <input
                  type="password"
                  className="form-control"
                  name="password_confirmation"
                  onChange={handleConfirmpasswordChange}
                /> */}

              {/* {confirmPassword && (
                  <div style={{ color: passwordMatches ? "green" : "red" }}>
                    {passwordMatches
                      ? "✔️ password match!"
                      : "❌ password don't match."}
                  </div>
                )} */}

              <ValidPassword
                label="Confirm Password"
                name="Confirm Password"
                id="keys"
                datatestid="key2"
                value={confirmPassword}
                setvalue={setConfirmPassword}
                comparewith={password}
                validcheck={{
                  passwordMatch: {
                    valid: false,
                    message: {
                      failure: "Passwords don't match!",
                      success: "Password matches",
                    },
                  },
                }}
              />
              {/* <label htmlFor="password_confirmation">Confirm Password</label> */}
              {/* </div> */}
              <div
                className="col-md-2 col-lg-4 mx-auto"
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "1rem",
                  fontSize: "14px",
                }}
              >
                By clicking the Signup button below, you agree to our{" "}
                <a href="/terms">Terms of Service</a> and{" "}
                <a href="/privacy">Privacy Policy</a>
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-lg btn-primary btn-login text-uppercase fw-bold"
                  style={{ fontSize: "16px" }}
                  onClick={handleSignUp}
                  type="submit"
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>
          <br />
          <br />
          <div className="mx-auto text-center mx-2">
            <span>
              If you are already a member, please <a href="/signin">Sign In</a>{" "}
              here.
            </span>
          </div>
          <div>
            <br />
            <hr />
            <div className="mx-auto text-center mx-2">
              <span>
                If you would like to Sign up as a Collector to collect and store
                Digital Collectibles, please{" "}
                <a href="https://collector.clifty.io/signup">Sign Up</a> here.
              </span>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
