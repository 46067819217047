import { useContext, useState } from "react";
import AlertPopup from "../Alert/alert";
import { useNavigate } from "react-router";
import axios from "axios";
import { AlertContext } from "../../App";
import { Link } from "react-router-dom";
import "./index.css";

const NewAward = () => {
  const [awardName, setAwardName] = useState();
  const [description, setDescription] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);

  const localEventId = window.location.pathname.split("/")[2];

  const handleNewAward = async (e) => {

    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    const file = document.querySelector("input[type=file]").files[0];
    setError(null);

    if (!user) {
      return navigate("/");
    }
    const errors = [];

    if (!awardName || awardName.length === 0) {
      errors.push("Award name is required");
    }
    if (!description || description?.length === 0) {
      errors.push("Please enter award description");
    }

    if (!file || file?.length === 0) {
      errors.push("File is required");
    }

    // File size should be less than 10MB
    if (file?.size > 10000000) {
      errors.push("File size should be less than 10MB");
    }

    if (
      !file.name.toLowerCase().includes("jpg") &&
      !file.name.toLowerCase().includes("png") &&
      !file.name.toLowerCase().includes("gif") &&
      !file.name.toLowerCase().includes("jpeg")
    ) {
      errors.push("File must be png or jpg or gif or jpeg");
    }

    console.log("errors", errors);
    if (errors.length > 0) {
      setError(errors);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    let ipfsHash;

    try {
      const ipfs = await axios.post(
        process.env.REACT_APP_BASEURL + "/nftarticle",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      ipfsHash = `ipfs://${ipfs?.data?.IpfsHash}`;
    } catch (err) {
      console.log("err", err);
    }

    const encodedDescription = encodeURIComponent(description);

    const res = await fetch(
      process.env.REACT_APP_BASEURL + `/members/events/${localEventId}/awards`,
      {
        method: "post",
        body: JSON.stringify({
          name: awardName,
          description: encodedDescription,
          awardimage: ipfsHash,
        }),
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );

    const data = await res.json();
    console.log(data);

    if (data["error"]) {
      setError(data["error"]);
      return;
    } else {
      alertContext.show = true;
      alertContext.variant = "primary";
      alertContext.msg = "A new award has been created!";
      navigate("/awards/" + localEventId);
    }
  };

  // Handle file upload
  const handleFilechange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // create a preview URL for the upload
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  const handleErrorpopup = () => {
    setError(null);
  };


  return (
    <div className="container">
      {error && (
        <AlertPopup
          message={error}
          variant="danger"
          setShow={handleErrorpopup}
        />
      )}
      <div className="col-md-2 col-sm-4 col-lg-10 mx-auto">
        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handleNewAward}
        >
          <Link to={`/awards/${localEventId}`} style={{ textDecoration: "none" }}>
            &larr; Back
          </Link>
          <h3 className="login-heading mb-4">New Award</h3>
          <div className="form-group mb-4">
            <label htmlFor="name">Award Name *</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={awardName}
              onChange={(e) => setAwardName(e.target.value)}
              maxLength={500}
              required
            />
          </div>

          <div className="form-group mb-4">
            <label htmlFor="description">Description *</label>
            <textarea
              rows={5}
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          <div className="form-group mb-4">
            <h3>Award Image</h3>
            <label htmlFor="artifact">Image (JPG or PNG or GIF) *</label>

            <input
              type="file"
              className="form-control"
              required
              name="artifact"
              accept="image/*"
              placeholder="jpeg or png or gif image"
              onChange={handleFilechange}
            />
            {imagePreview && (
              <div>
                <img
                  src={imagePreview}
                  alt="preview"
                  style={{
                    height: "100px",
                    width: "100px",
                    marginTop: "10px",
                  }}
                />
              </div>
            )}
          </div>

          <div className="d-grid">
            <button
              type="submit"
              className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAward;
