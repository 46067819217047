import { useContext, useState } from "react";
import AlertPopup from "../Alert/alert";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import { Link } from "react-router-dom";
import "./index.css";

const AwardAssign = () => {
  const [recipientName, setRecipientName] = useState();
  const [recipientEmail, setRecipientEmail] = useState();
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);

  const localEventId = window.location.pathname.split("/")[2];
  const localAwardId = window.location.pathname.split("/")[4];

  const handleNewAward = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    setError(null);

    if (!user) {
      return navigate("/");
    }
    const errors = [];

    if (!recipientName || recipientName.length === 0) {
      errors.push("Award name is required");
    }

    console.log("errors", errors);
    if (errors.length > 0) {
      setError(errors);
      return;
    }

    const res = await fetch(
      process.env.REACT_APP_BASEURL +
        `/members/events/awards/${localAwardId}/assign`,
      {
        method: "PUT",
        body: JSON.stringify({
          recipientname: recipientName,
          recipientemail: recipientEmail,
        }),
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );

    const data = await res.json();
    console.log(data);

    if (data["error"]) {
      setError(data["error"]);
      return;
    } else {
      alertContext.show = true;
      alertContext.variant = "primary";
      alertContext.msg = "Assigned the award to " + recipientName;
      navigate("/awards/" + localEventId);
    }
  };

  const handleErrorpopup = () => {
    setError(null);
  };

  return (
    <div className="container">
      {error && (
        <AlertPopup
          message={error}
          variant="danger"
          setShow={handleErrorpopup}
        />
      )}
      <div className="col-md-2 col-sm-4 col-lg-10 mx-auto">
        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handleNewAward}
        >
          {/* <Link to={"/awards/" + localAwardId} style={{ textDecoration: "none" }}> */}
          <Link onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
            &larr; Back
          </Link>
          <h3 className="login-heading mb-4">Assign Award</h3>
          <div className="form-group mb-4">
            <label htmlFor="name">Recipient Name *</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
              maxLength={500}
              required
            />
          </div>

          <div className="form-group mb-4">
            <label htmlFor="name">Recipient Email *</label>
            <input
              type="text"
              name="email"
              className="form-control"
              value={recipientEmail}
              onChange={(e) => setRecipientEmail(e.target.value)}
              maxLength={500}
              required
            />
          </div>

          <div className="d-grid">
            <button
              type="submit"
              className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AwardAssign;
