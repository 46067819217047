import { useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../App";

const EventsTableList = () => {
  const navigate = useNavigate();
  const [allevents, setPins] = useState();

  const alertContext = useContext(AlertContext);

  useEffect(() => {
    getPins();
  }, [alertContext, navigate]);

  const getPins = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/");
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_BASEURL}/members/events`, {
        headers: {
          Authorization: `Bearer ${user.sessionToken}`,
        },
      });

      const data = await res.json();
      const patharray = window.location.pathname.split("/");
      const type = patharray[patharray.length - 1];
      if (type === "active") {
        setPins(data?.filter((pin) => pin?.active === 1));
      } else if (type === "inactive") {
        setPins(data?.filter((pin) => pin?.active === 0));
      } else {
        setPins(data);
      }
    } catch (err) {
      alertContext.show = true;
      alertContext.variant = "danger";
      if (err.toString()?.includes("Forbidden")) {
        alertContext.msg = "Session expired, please sign in";
        localStorage.removeItem("user");
        navigate("/");
      } else {
        alertContext.msg = "Error getting pins";
      }
    }
  };

  const handleactivate = (e, id) => {
    const { name } = e.target;
    const user = JSON.parse(localStorage.getItem("user"));
    fetch(`${process.env.REACT_APP_BASEURL}/members/pins/${id}/${name}`, {
      headers: {
        Authorization: `Bearer ${user.sessionToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .finally(() => getPins());
  };
  return (
    <>
      <div className="row">
        <div className="col-2">
          <b>Event Name</b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b
            title="Number of PiNs allocated for the event."
            data-toggle="tooltip"
          >
            PiNs
          </b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b title="Souvenir PiNs claimed/waitlisted" data-toggle="tooltip">
            Souvenirs
          </b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b title="Presentation PiNs" data-toggle="tooltip">
            Presentations
          </b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b title="Awards" data-toggle="tooltip">
            Awards
          </b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b>Status</b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b>Action</b>
        </div>
      </div>



      {allevents &&
        allevents.map((anEvent) => {
          return (
            <div className="row" key={anEvent.id}>
              <div
                className="col-2"
                style={{
                  verticalAlign: "top",
                }}
              >
                <a className="detail-link" href={`/events/${anEvent?.id}`}>
                  <span title={anEvent.name}>
                    {anEvent.name.length > 25 ? `${anEvent.name.substring(0, 25)}...` : anEvent.name}
                  </span>
                </a>
              </div>
              <div className="col" style={{ textAlign: "center" }}>
                {anEvent.maxtokens}
              </div>
              <div className="col" style={{ textAlign: "center" }}>
                {anEvent.souvenirclaimed > 0 ? (
                  <a
                    className="detail-link"
                    href={`/tokens/${anEvent?.id}`}
                  >
                    {anEvent.souvenirclaimed}
                  </a>
                ) : (
                  "0"
                )}
                &nbsp; / &nbsp;
                {anEvent.souvenirwaitlisted > 0 ? (
                  <a
                    className="detail-link"
                    href={`/eventwaitlist?eventid=${anEvent?.id}`}
                  >
                    {anEvent.souvenirwaitlisted}
                  </a>
                ) : (
                  "0"
                )}
              </div>

              <div className="col" style={{ textAlign: "center" }}>
                <a className="detail-link" href={`/presentations/${anEvent?.id}`}>
                  {anEvent.presentations}
                </a>
              </div>

              <div className="col" style={{ textAlign: "center" }}>
                <a className="detail-link" href={`/awards/${anEvent?.id}`}>
                  {anEvent.awards}
                </a>
              </div>

              <div className="col" style={{ textAlign: "center" }}>
                {anEvent.active ? "Active" : "Inactive"}
              </div>
              <div style={{ textAlign: "center" }} className="col">
                {anEvent.active === 0 ? (
                  <button
                    name="Enable"
                    className="active-navlink"
                    onClick={(e) => handleactivate(e, anEvent.id)}
                    style={{ border: "none" }}
                    title="Enable this event"
                  >
                    Enable
                  </button>
                ) : (
                  <button
                    name="Disable"
                    onClick={(e) => handleactivate(e, anEvent.id)}
                    style={{ border: "none" }}
                    title="Disable this event"
                  >
                    Disable
                  </button>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default EventsTableList;
